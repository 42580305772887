import React, { useState } from "react";
import { Spinner, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
const { REACT_APP_PAST_INCIDENT } = process.env;
//Added as part of IT-4456 to remove iframe scrolling
var customjs = require('./customJavascript');
//end of IT-4456
const PastIncident = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const hideSpinner = () => {
    setLoading(false);
    //Added as part of IT-4456 to remove iframe scrolling
    customjs.setIframeHeight();   
    //end of IT-4456 
  };

  return (
    <div style={{ background: "#F8F8F8" }}>
      <div style={{ float: "right", padding: "1rem 1rem 0 0" }}>
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </div>
      {loading ? (
        <div
          style={{
            height: "800px",
            margin: "auto",
            width: "50%",
            padding: "10rem 1rem 1rem 1rem ",
            textAlign: "center",
          }}
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <></>
      )}
      <div style={loading ? { display: "none" } : { display: "" }}>
        <iframe
          width="100%"
          src={REACT_APP_PAST_INCIDENT}
          title="status page"
          onLoad={hideSpinner}
        ></iframe>
      </div>
    </div>
  );
};

export default PastIncident;
