import React from "react";
import BannerContent from "./BannerContent";

const Banner = () => {
  return (
    <div className="row">
      <div className="heroForm">
        <BannerContent />
        <div className="heroForm__bg">
          <div className="heroForm__img heroForm__img--banner"></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
