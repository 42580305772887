import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Community from "../../asserts/QlikCloud-StatusPG-Community.jpg";
import Support from "../../asserts/QlikCloud-StatusPG-Support.jpg";
import MoreDetails from "../../asserts/QlikCloud-StatusPG-More-Details.jpg";
import "./SubFooter.css";
import {
  support_Portal,
  community_Help,
  more_Details,
  past_Details,
} from "../../config";

const Footer = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  let navigate = useNavigate();

  const handlePastIncident = () => {
    navigate("pastIncident");
    window.scrollTo(0, 0);
  };

  return (
    <div className="subFooterWrapper t-stone">
      {!isAuthenticated ? (
        <Container>
          <Row className="blocks--equal">
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card>
                <Card.Img
                  className="card_pointer"
                  onClick={() =>
                    window.open(
                      "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                      "_self"
                    )
                  }
                  variant="top"
                  src={Support}
                />
                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title
                      onClick={() =>
                        window.open(
                          "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                          "_self"
                        )
                      }
                      className="hdg--center card_pointer"
                    >
                      Support
                    </Card.Title>
                    <Card.Text
                      className="card_pointer"
                      onClick={() =>
                        window.open(
                          "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                          "_self"
                        )
                      }
                    >
                      {support_Portal.data}
                    </Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card>
                <Card.Img
                  className="card_pointer"
                  onClick={() =>
                    window.open("https://community.qlik.com/", "_self")
                  }
                  variant="top"
                  src={Community}
                />
                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title
                      onClick={() =>
                        window.open("https://community.qlik.com/", "_self")
                      }
                      className="hdg--center card_pointer"
                    >
                      Community
                    </Card.Title>
                    <Card.Text
                      className="card_pointer"
                      onClick={() =>
                        window.open("https://community.qlik.com/", "_self")
                      }
                    >
                      {community_Help.data}
                    </Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card>
                <Card.Img
                  className="card_pointer"
                  onClick={() => loginWithRedirect()}
                  variant="top"
                  src={MoreDetails}
                />

                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title
                      className="hdg--center card_pointer"
                      onClick={() => loginWithRedirect()}
                    >
                      More details
                    </Card.Title>
                    <Card.Text
                      className="card_pointer"
                      onClick={() => loginWithRedirect()}
                    >
                      {more_Details.data}
                    </Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row className="blocks--equal">
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card>
                <Card.Img
                  className="card_pointer"
                  onClick={() =>
                    window.open(
                      "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                      "_self"
                    )
                  }
                  variant="top"
                  src={Support}
                />
                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title
                      onClick={() =>
                        window.open(
                          "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                          "_self"
                        )
                      }
                      className="hdg--center card_pointer"
                    >
                      Support
                    </Card.Title>
                    <Card.Text
                      className="card_pointer"
                      onClick={() =>
                        window.open(
                          "https://community.qlik.com/t5/Support/ct-p/qlikSupport",
                          "_self"
                        )
                      }
                    >
                      {support_Portal.data}
                    </Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card>
                <Card.Img
                  className="card_pointer"
                  onClick={() =>
                    window.open("https://community.qlik.com/", "_self")
                  }
                  variant="top"
                  src={Community}
                />
                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title
                      onClick={() =>
                        window.open("https://community.qlik.com/", "_self")
                      }
                      className="hdg--center card_pointer"
                    >
                      Community
                    </Card.Title>
                    <Card.Text
                      className="card_pointer"
                      onClick={() =>
                        window.open("https://community.qlik.com/", "_self")
                      }
                    >
                      {community_Help.data}
                    </Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
            <Col xs={12} md={4} lg={4} className="cardCol cardStyleNotAut">
              <Card onClick={handlePastIncident} className="card_pointer">
                <Card.Img variant="top" src={MoreDetails} />
                <section className="card__main card__main--tiny">
                  <Card.Body className="padding__unset">
                    <Card.Title className="hdg--center">
                      Past Incidents
                    </Card.Title>
                    <Card.Text>{past_Details.data}</Card.Text>
                  </Card.Body>
                </section>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Footer;
