import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";

const NotFoundPage = () => {
  return (
    <>
      <div className="t-light t-alabaster inline-bg">
        <Row className="row--bottom-x12 row--top-x12 row--404">
          <Container>
            <div className="wrapper wrapper--loose">
              <section>
                <div className="section__row section__row--x2">
                  <h2 className="hdg  hdg--fs1dot1 textAlignment--center">
                    Sorry, the page you were looking for cannot be found.
                  </h2>
                </div>
                <div className="section__row">
                  <div className="userContent textAlignment--center">
                    <div className="section">
                      <div className="section__row section__row--x2"></div>
                      <div className="section__row section__row--x2 section__row--center">
                        <a href="/" className="text__green trigger trigger--fs1">
                          Go to the homepage
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Container>
        </Row>
      </div>
    </>
  );
};

export default NotFoundPage;
