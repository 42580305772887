export const banner_content = {
  header: "Qlik Cloud Operation Health",
  line1: "Up-to-date information on the status of the Qlik Cloud services.",
  line2:"",
    // "The charts below outline our components health – from fully operational, to degraded, to full outages. If you are experiencing an issue not listed here, please visit Support.",
};

export const support_Portal = {
  data: "Get help. Quickly search across all our knowledge libraries and Qlik Community to solve an issue, or log in to file a ticket for personalized assistance.",
};

export const community_Help = {
  data: "Get connected. Collaborate with tens of thousands of Qlik technologists, customers, and influencers worldwide – no tickets, just answers.",
};

export const more_Details = {
  data: "Get more information on past incidents and uptime for Qlik Cloud.",
};

export const past_Details = {
  data: "Get more information on past incidents.",
};
