//IT-4456 custom javascript to set iframe height dynamically
exports.setIframeHeight = function setIframeHeight () {
  var obj = document.getElementsByTagName("iframe")[0];
  
  // Listen for messages sent from the iFrame
  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  
  eventer(messageEvent,function(e) {
    // If the message is a resize frame request
    if (e.data.indexOf('resize::') != -1) {
      var height = e.data.replace('resize::', ''); 
      height = parseInt(height)+100;     
      obj.style.height = height+'px';
    }
  } ,false);  
  };
  //end of IT-4456

  