import React, { useState } from "react";
import { Spinner } from "react-bootstrap";

const { REACT_APP_UNAUTH_IFRAME } = process.env;
//Added as part of IT-4456 to remove iframe scrolling
var customjs = require('./customJavascript');
//end of IT-4456
const Home = () => {
  const [loading, setLoading] = useState(true);

  const hideSpinner = () => {
    setLoading(false);
    //Added as part of IT-4456 to remove iframe scrolling
    customjs.setIframeHeight();   
    //end of IT-4456 
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "800px",
            margin: "auto",
            width: "50%",
            padding: "10rem 1rem 1rem 1rem ",
            textAlign: "center",
          }}
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <></>
      )}
      <div style={loading ? { display: "none" } : { display: "" }}>
        <iframe
          width="100%"
          src={REACT_APP_UNAUTH_IFRAME}
          title="status page"
          onLoad={hideSpinner}
        ></iframe>
      </div>
    </>
  );
};


export default Home;
