import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "react-bootstrap";

import Home from "./components/Home";
import NotFoundPage from "./components/NotFoundPage";
import Header from "./components/Header/Header";
import SubFooter from "./components/SubFooter/SubFooter";
import AuthHome from "./components/authHome";
import Footer from "./components/Footer/Footer";
import Banner from "./components/Banner/Banner";
import { AuthRouter } from "./AuthRouter";
import PastIncident from "./components/pastIncident";
//Commenting as part of IT-4477 to replace chatbot
//import Chatbot from "./components/Chatbot";
//end of IT-4477

const App = () => {
  const HomePage = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
      <>
        {!isAuthenticated && !isLoading && <Home />}
        {isLoading && (
          <div
            style={{
              height: "800px",
              margin: "auto",
              width: "50%",
              padding: "10rem 1rem 1rem 1rem ",
              textAlign: "center",
            }}
          >
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {isAuthenticated && <AuthHome />}
      </>
    );
  };

  return (
    <>
      <Header />
      <Banner />
      <Routes>
        {/* <Route path="/" element={!isAuthenticated ? <Home /> : <AuthHome />} /> */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/StagingUnauthPage.html" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/pastIncident"
          element={
            <AuthRouter>
              <PastIncident />
            </AuthRouter>
          }
        />
      </Routes>

      <SubFooter />
      <div className="footer" style={{ backgroundColor: "#54565a" }}>
        <Footer />
      </div>
    </>
  );
};

export default App;
