import React from "react";
import { Row, Col, Button, Container } from 'react-bootstrap'

const Footer = () => {
  return  <Container>
  <div className="t-dark t-qlikGray">  
  <Container>
  <Row>
    <Col xs={12} md={6} lg={3} className="row--bottom row--bottom-x2">
      <Row><span className="navList__label">Products</span></Row>
      <Row><a href="https://www.qlik.com/us/products" className="navList__trigger" >All Products</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-active-intelligence-platform" className="navList__trigger" >Qlik Cloud Platform</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-sense" className="navList__trigger" >Qlik Sense</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-automl" className="navList__trigger" >Qlik AutoML</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlikview" className="navList__trigger" >QlikView</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-replicate" className="navList__trigger" >Qlik Replicate</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-compose-data-lakes" className="navList__trigger" >Qlik Compose for Data Lakes</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-compose-data-warehouses" className="navList__trigger" >Qlik Compose for Data Warehouse</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-enterprise-manager" className="navList__trigger" >Qlik Enterprise Manager</a></Row>
      <Row><a href="https://www.qlik.com/us/products/catalog-and-lineage" className="navList__trigger" >Catalog &amp; Lineage</a></Row>
      <Row><a href="https://www.qlik.com/us/products/qlik-gold-client" className="navList__trigger" >Qlik Gold Client</a></Row>
      <Row><a href="https://www.qlik.com/us/products/why-qlik-is-different" className="navList__trigger" >Why Qlik</a></Row>
    </Col>

    <Col xs={12} md={6} lg={3} className="row--bottom row--bottom-x2">
      <Row><span className="navList__label">Resources</span></Row>
      <Row><a href="https://www.qlik.com/us/resource-library" className="navList__trigger" >Resource Library</a></Row>
      <Row><a href="https://www.qlik.com/us/partners" className="navList__trigger" >Qlik Partners</a></Row>
      <Row><a href="https://www.qlik.com/us/trial/qlik-free-trials" className="navList__trigger" >Free Trials</a></Row>
      <Row><a href="https://www.qlik.com/us/compare" className="navList__trigger" >Compare Qlik</a></Row>
      <Row><a href="https://www.qlik.com/us/learn/glossary" className="navList__trigger" >Glossary</a></Row>
      <Row><a href="https://www.qlik.com/us/services/training" className="navList__trigger" >Training</a></Row>
      <Row><a href="https://www.qlik.com/us/services/support" className="navList__trigger" >Support</a></Row>
    </Col>

    <Col xs={12} md={6} lg={3} className="row--bottom row--bottom-x2">
      <Row>
        <span className="navList__label">Company</span>
      </Row>
      <Row><a href="https://www.qlik.com/us/company" className="navList__trigger" title="About QQlik" >About Qlik</a></Row>
      <Row><a href="https://www.qlik.com/us/company/press-room" className="navList__trigger" >Press Room</a></Row>
      <Row><a href="https://www.qlik.com/us/trust" className="navList__trigger" >Trust &amp; Security</a></Row>
      <Row><a href="https://www.qlik.com/us/trust/accessibility" className="navList__trigger" >Accessibility</a></Row>
      <Row><a href="https://www.qlik.com/us/trust/privacy" className="navList__trigger" >Privacy</a></Row>
      <Row><a href="https://www.qlik.com/us/company/careers" className="navList__trigger" >Careers</a></Row>
      <br/>
      <Row><a href="https://www.qlik.com/us/contact" className="navList__trigger" >Global Offices</a></Row>
      <Row><a href="https://www.qlik.com/us/try-or-buy/buy-now" className="navList__trigger" >Contact Sales</a></Row>
    </Col>

    <Col xs={12} md={6} lg={3} className="row--bottom row--bottom-x2">
      <Row>
          <span className="navList__label">Legal</span>
      </Row>
      <Row>
          <span className="navList__trigger">© 1993-2022 QlikTech International AB, All Rights Reserved</span>
      </Row>
      <br/>
      <Row><a href="https://www.qlik.com/us/legal/legal-policies" className="navList__trigger" >Legal Policies</a></Row>
      <Row><a href="https://www.qlik.com/us/legal/trademarks" className="navList__trigger" >Trademarks</a></Row>
      <Row><a href="https://www.qlik.com/us/legal/terms-of-use" className="navList__trigger" >Terms of Use</a></Row>
      <Row><a href="https://www.qlik.com/us/legal/legal-agreements" className="navList__trigger" >Legal Agreements</a></Row>
      <Row><a href="https://www.qlik.com/us/legal/product-terms" className="navList__trigger" >Product Terms</a></Row>
    </Col>
  </Row>
  </Container>
  </div>
</Container>;
};

export default Footer;
