import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { banner_content } from "../../config";

const BannerContent = () => {
  return (
    <Container className="t-dark t-qlikGray ">
      <Row>
        <div className="heroForm__content">
          <div className="heroCampaignAlt__banner">
            <div className="heroForm__grid row--bottom-x1">
              <Col xs={12} md={12} lg={6}>
                <div className="heroForm__container">
                  <div className="heroForm__bd heroForm__bd--short">
                    <div className="section">
                      <Row className="section__row heroCampaignAlt__container">
                        <h1 className="hdg hdg--fs1dot3">
                          {banner_content.header}
                        </h1>
                      </Row>
                      <Row className="section__row section__row--compact">
                        <h2 className="hdg hdg--fs4">{banner_content.line1}</h2>
                      </Row>
                      <Row className="section__row section__row--compact">
                        <div className="userContent userContent--fs2">
                          <p>{banner_content.line2}</p>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default BannerContent;
